import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import swal from 'sweetalert';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ReceiptIcon from '@material-ui/icons/Receipt';
import LocalMallIcon from '@material-ui/icons/LocalMall';















const SubCategoriesProduct = (props)=> {
    const [CategoriesUrl , setCategoriesUrl] = useState("")
    const [Product , setProduct] = useState([])
    const [Product1 , setProduct1] = useState([])
    const [Product2 , setProduct2] = useState([])
    const [Categories , setCategories] = useState([])
    const [fal , setFal] = useState(false)
    const [fal1 , setFal1] = useState(false)
    const [CategoriesName , setCategoriesname] = useState({})
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [subCate , setsubCate] = useState([])
    const [allproduct , setallproduct] = useState([])
    const [Subproduct , setSubproduct] = useState([])
    const [Subproduct1 , setSubproduct1] = useState([])
    const [SortedData , setSortedData] = useState([])
    const [SortedName , setSortedName] = useState([])
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [DataPart2 , setDataPart2] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    const [dispaluy53 , setdispaluy53] = useState(true)
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])
    // categoriesName subcategories

    useEffect(() =>{

        window.scrollTo(0, 0)
        // console.log( props.match.params);
        let Categoriesname = props.match.params ;
        setCategoriesname(Categoriesname)

        // const Inter = setInterval(()=>{
            //  if (!JSON.parse(localStorage.getItem("CateProduct")) ){
            //  var data1 = JSON.parse(localStorage.getItem("Cate")) 

        // var Category =  props.match.url.split("/")
        // setCategoriesUrl(data1)
        // var Category1 =  CategoriesUrl.split("-").join(" ")
        fetch("https://chand-rentacar-main-back.vercel.app/AllProduct",{
                    method: "GET",
                    headers :  {
                    "Content-Type" : "application/json" , 
                } ,
                })
                .then(res7=>res7.json())
                .then(res8=>{
                    setallproduct(res8)
                    const NonActive = res8.filter((res9,i)=>{
                        // console.log(res9.status , res9.Product_Catagories , Categoriesname.categoriesName  , res9.Product_Sub_Catagories , Categoriesname.subCate );
                        if(res9.status && res9.Product_Catagories === Categoriesname.categoriesName  ){
                            return res9 
                        }
                    })

                    setSubproduct(NonActive)
                    console.log(NonActive)
                })

        
        fetch("https://chand-rentacar-main-back.vercel.app/AllCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            // console.log(res3);
            // const Data1 = res3.filter((res4,i)=>{
            //     if (res4.MainCategories === props.history.location.pathname.split("/")[2])
            //     return res4
            // })
            // console.log(Data1,res3)
            setSubCategories(res3)
            // setSubCategoriesname(res3)
            fetch("https://chand-rentacar-main-back.vercel.app/MainCatogories",{
              method: "GET",
               headers :  {
               "Content-Type" : "application/json" , 
           } ,
          })
          .then(res12=>res12.json())
          .then(res13=>{
            let a = []
            // console.log(res3)
            for (let i = 0; i < res13.length; i++) {
            for (let j = 0; j < res3.length; j++) {
              // console.log(res3[j].MainCategories , res13[i].MainCategories)
              if(res3[j].MainCategories === res13[i].MainCategories){
                // a.push("1")
  // console.log(i,j)
                a[i] = "1"
                break
              }
              else{
                a[i] = "0"
                // a.pusH(("0"))
              }
              
            }
            }
            // console.log(a)
            setCate1(a)
          })
        })
  
  
        fetch("https://chand-rentacar-main-back.vercel.app/CourseAllSubCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            let data = res3.sort((a, b) => {
                return a.sort - b.sort;
            });
            // console.log(data);
            setSubClassCategories(data)


            fetch("https://chand-rentacar-main-back.vercel.app/AllCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res12=>res12.json())
        .then(res13=>{
          let a = []
          console.log(res3,res13)
          for (let i = 0; i < res13.length; i++) {
          for (let j = 0; j < res3.length; j++) {
            // console.log(res3[j].MainCategories , res13[i].MainCategories , res3[j].CategoriesName , res13[i].CategoriesName )
            if(res3[j].MainCategories === res13[i].MainCategories && res3[j].CategoriesName === res13[i].CategoriesName ){
              // a.push("1")
console.log(i,j)
              a[i] = "1"
              break
            }
            else{
              a[i] = "0"
              // a.pusH(("0"))
            }
            
          }
          }
          console.log(a)
          setCate2(a)
        })
        })
        fetch("https://chand-rentacar-main-back.vercel.app/slider-photo",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res=>res.json())
        .then(res1=>{
            // console.log(res1[0]);
            setSliderPhoto(res1)
        
        })


        
                    fetch("https://chand-rentacar-main-back.vercel.app/TagsSort1111",{
                    method: "GET",
                        headers :  {
                        "Content-Type" : "application/json" , 
                    } ,
                })
                .then(res9=>res9.json())
                .then(res8=>{
                    const dta = res8.filter((res7,i)=>{
                        if(res7.MainCategories ===Categoriesname.categoriesName  && res7.New_Sub_Categories === Categoriesname.subcategories) {
                            // console.log(res7);
                            return res7
                        }
                    })
                    // console.log(dta);
                    setSortedData(dta)
                })
                fetch("https://chand-rentacar-main-back.vercel.app/MainCatogories",{
                  method: "GET",
                   headers :  {
                   "Content-Type" : "application/json" , 
               } ,
              })
              .then(res2=>res2.json())
              .then(res3=>{
                console.log(res3);
                // setMainCatogories(res3)
                
    
                let dat = []
                res3.map((res55,c)=>{
                    dat.push(res55)
                })
                // console.log(SubClassCategories,asse)
                console.log(dat)
                let data = dat.sort((a, b) => {
                    return a.sort - b.sort;
                });
                setMainCatogories(data)
                
              })
                    fetch("https://chand-rentacar-main-back.vercel.app/AllCategories",{
                    method: "GET",
                        headers :  {
                        "Content-Type" : "application/json" , 
                    } ,
                })
                .then(res4=>res4.json())
                .then(res5=>{
                    let data = res5.filter((res6,i)=>{
                        if (res6.MainCategories === Categoriesname.categoriesName) return res6
                    })
                    setsubCate(data)
                    // console.log(res5,data);
                })

                fetch("https://chand-rentacar-main-back.vercel.app/CourseAllSubCategories",{
                  method: "GET",
                   headers :  {
                   "Content-Type" : "application/json" , 
               } ,
              })
              .then(res2=>res2.json())
              .then(res3=>{
                  let data = res3.sort((a, b) => {
                      return a.sort - b.sort;
                  });
                  // console.log(data);
                  setSubClassCategories(data)
              })

                // fetch("https://chand-rentacar-main-back.vercel.app/AllProduct",{
                //     method: "GET",
                //     headers :  {
                //     "Content-Type" : "application/json" , 
                // } ,
                // })
                // .then(res7=>res7.json())
                // .then(res8=>{
                //     setallproduct(res8)
                //     const NonActive = res8.filter((res9,i)=>{
                //         console.log(res9.status , res9.Product_Catagories , Categoriesname.categoriesName  , res9.Product_Sub_Catagories , Categoriesname.subCate );
                //         if(res9.status && res9.Product_Catagories === Categoriesname.categoriesName  ){
                //             return res9 
                //         }
                //     })

                //     setSubproduct(NonActive)
                //     console.log(NonActive)
                // })

        

      



},[])


    const setSortPrice1 = (e)=>{
      setdispaluy53(!dispaluy53)
      if(Subproduct.length !== 0){

        SortedName.push(e)
        console.log(e,Subproduct);
        if (e === "Low"){
            const Pro6 = Subproduct.sort(function (a, b) {
                    return parseFloat(a.Product_Price) - parseFloat(b.Product_Price)  
                  });
                  console.log(Pro6);
                  let reversed_array = [];
                  Pro6.forEach((element) => {
                    reversed_array.unshift(element);
                });
                  console.log(Pro6,reversed_array)
                  setSubproduct(reversed_array)
                  setSubproduct(Pro6)
                  setdispaluy53(!dispaluy53)
                  setdispaluy53(!dispaluy53)
    
        }
        if (e === "High"){
            const Pro6 = Subproduct.sort((a, b)=> {
                    return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                    
                });
              //   let reversed_array = [];
              //   Pro6.forEach((element) => {
              //     reversed_array.unshift(element);
              // });
              //   console.log(Pro6,reversed_array)
                setSubproduct(Pro6)
                setdispaluy53(!dispaluy53)
                setdispaluy53(!dispaluy53)

        }
        if (e === "A"){
            const Pro6 = Subproduct.sort((a, b)=> {
                var nameA = a.Product_Name.toUpperCase(); 
                var nameB = b.Product_Name.toUpperCase(); 
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
    
                return 0;
                });
                console.log(Pro6);
                setSubproduct(Pro6)
                
              }
              
              setSubproduct1([])
              setdispaluy53(!dispaluy53)

            }
            else{
        
                SortedName.push(e)
                console.log(e);
                if (e === "Low"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                            return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                            
                        });
                        setSubproduct1(Pro6)
            
                }
                if (e === "High"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                            return parseFloat(b.Product_Price) - parseFloat(a.Product_Price)  
                            
                        });
                        setSubproduct1(Pro6.reverse())
            
                }
                if (e === "A"){
                    const Pro6 = Subproduct.sort((a, b)=> {
                        var nameA = a.Product_Name.toUpperCase(); 
                        var nameB = b.Product_Name.toUpperCase(); 
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
            
                        return 0;
                        });
                        console.log(Pro6);
                        setSubproduct1(Pro6)
                        
                      }
                      
                      
                      setSubproduct([])
                    }
                    setdispaluy53(!dispaluy53)

                  }
  

  
const addtocartproduct = (productDetail) =>{
  swal("Update!", "Your Item Add in Cart!", "success");

      
  // document.getElementById("myDi").style.visibility = "visible"
  // setTimeout(()=>{
  // document.getElementById("myDi").style.visibility = "hidden"

  // },1000)
  console.log(productDetail)
  if (productDetail[0].Product_Price_Discounted === null){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Product_Price_Discounted){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Size_Discounted[0]){
      
          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Size_Discounted[0],
              Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else{




          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Price[0],
              Total_Product_Price  : productDetail[0].Price[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }


  }


      

}


const addtocartproduct8 = (productDetail) =>{
    swal("Update!", "Item Save!", "success");
  
        
    // document.getElementById("myDi").style.visibility = "visible"
    // setTimeout(()=>{
    // document.getElementById("myDi").style.visibility = "hidden"
  
    // },1000)
    console.log(productDetail)
    if (productDetail[0].Product_Price_Discounted === null){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Product_Price_Discounted){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Size_Discounted[0]){
        
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Size_Discounted[0],
                Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else{
  
  
  
  
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Price[0],
                Total_Product_Price  : productDetail[0].Price[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
  
    }
  
  
        
  
  }
  
        return (
       




<>
          <main className="main">
          <div className="page-header text-center" style={{backgroundImage: 'url("assets/images/page-header-bg.jpg")'}}>
            <div className="container">
              <h1 className="page-title">{CategoriesName.categoriesName}<span></span></h1>
            </div>{/* End .container */}
          </div>{/* End .page-header */}
          <nav aria-label="breadcrumb" className="breadcrumb-nav mb-2">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="">Home</a></li>
                {/* <li className="breadcrumb-item"><a href="#">Shop</a></li> */}
                <li className="breadcrumb-item active" aria-current="page">{CategoriesName.categoriesName}</li>
              </ol>
            </div>{/* End .container */}
          </nav>{/* End .breadcrumb-nav */}
          <div className="page-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="toolbox">
                    <div className="toolbox-left">
                      <div className="toolbox-info">
                        Showing <span>{Subproduct.length} of {Subproduct.length}</span> Products
                      </div>{/* End .toolbox-info */}
                    </div>{/* End .toolbox-left */}
                    <div className="toolbox-right">
                      <div className="toolbox-sort">
                        <label htmlFor="sortby">Sort by:</label>
                        <div className="select-custom">
                          <select name="sortby" id="sortby" className="form-control">
                            <option value="popularity" selected="selected">Most Popular</option>
                            <option value="Low" onClick={()=>setSortPrice1("Low")}>Low → High</option>
                          <option value="High" onClick={()=>setSortPrice1("High")}>High → Low</option>
                          <option value="A" onClick={()=>setSortPrice1("A")}>A → Z</option>
                            <option value="rating">Most Rated</option>
                            <option value="date">Date</option>
                          </select>
                        </div>
                      </div>{/* End .toolbox-sort */}
                      
                    </div>{/* End .toolbox-right */}
                  </div>{/* End .toolbox */}
                  <div className="products mb-3">
                    <div className="row justify-content-center">


                    {Subproduct.map((res,i)=>{
                       if(res.Price[0]){

                        return(
      <div className="col-6 col-md-4 col-lg-4 col-xl-3">
                              <div className="product product-7 text-center" style={{height : "auto"}}>
                                <figure className="product-media">
                                  <span className="product-label label-new">New</span>
                                  <Link  to={"/main-categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                                    <img src={res.Product_Image_Upload} alt="Product image" className="product-image" />
                                  </Link>
                                  <div className="product-action-vertical">
                                    <a className="btn-product-icon btn-wishlist btn-expandable"  onClick={()=>addtocartproduct8([res])}><span>add to wishlist</span></a>
                                    <Link to={"/main-categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></Link>
                                    {/* <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a> */}
                                  </div>{/* End .product-action-vertical */}
                                  <div className="product-action">
                                    <a className="btn-product btn-cart"  onClick={()=>addtocartproduct([res])}><span>Book Now</span></a>
                                  </div>{/* End .product-action */}
                                </figure>{/* End .product-media */}
                                <div className="product-body">
                                  <div className="product-cat">
                                    <Link to={"/main-categories/"+res.Product_Catagories}>{res.Product_Catagories}</Link>
                                  </div>{/* End .product-cat */}
                                  <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden"}}><Link to={"/main-categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link></h3>{/* End .product-title */}
                                  <div className="product-price">
                                  
                                    {res.Size_Discounted[0]  ? 
                        <><span className="product-price">Rs. {res.Size_Discounted[0]}</span><span className="text-muted ml-2" style={{padding :"0px"}}><del className="product-price" style={{color : "grey"}}>Rs. {(res.Price[0])}</del></span></>
                        :
                        <span>
                        Rs. {(res.Price[0])}
                        </span> 
                        
                                          }   
      
      
                                  </div>{/* End .product-price */}
                               
                                  <div className="product-action position-relative visible">
                                <a className="btn-product btn-cart text-uppercase text-dark text-decoration-none" title="Book Now" onClick={()=>addtocartproduct([res])}>
                                    <span className="text-dark shadow-none">Book Now</span>
                                  </a>
                                </div>
                                    
                                </div>{/* End .product-body */}
                              </div>{/* End .product */}
                            </div>
       )
      
        }
      
                      else{
                if(res.Course_Sub_Catagories !== ""){
                  return(
<div className="col-6 col-md-4 col-lg-4 col-xl-3">
                        <div className="product product-7 text-center" style={{height : "auto"}}>
                          <figure className="product-media">
                            <span className="product-label label-new">New</span>
                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                              <img src={res.Product_Image_Upload} alt="Product image" className="product-image" />
                            </Link>
                            <div className="product-action-vertical">
                              <a className="btn-product-icon btn-wishlist btn-expandable"  onClick={()=>addtocartproduct8([res])}><span>add to wishlist</span></a>
                              <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></Link>
                              {/* <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a> */}
                            </div>{/* End .product-action-vertical */}
                           {/* End .product-action */}
                          </figure>{/* End .product-media */}
                          <div className="product-body">
                            <div className="product-cat">
                              <Link to={"/categories/"+res.Product_Catagories}>{res.Product_Catagories}</Link>
                            </div>{/* End .product-cat */}
                            <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden"}}><Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link></h3>{/* End .product-title */}
                            <div className="product-price">
                            
                              {res.Product_Price_Discounted ? 
                  <><span className="product-price">Rs. {res.Product_Price_Discounted}</span><span className="text-muted ml-2" style={{padding :"0px"}}><del className="product-price" style={{color : "grey"}}>Rs. {(res.Product_Price)}</del></span></>
                  :
                  <span>
                  Rs. {(res.Product_Price)}
                  </span> 
                  
                                    }   


                            </div>{/* End .product-price */}
                                
{ res && res.luggage &&  res.luggage > 0 ?
  <div style={{display :"flex",flexDirection : "row",justifyContent :"space-around",padding:"5px 0px",width:"100%",margin:"auto"}}>
  <div>
    <div style={{display:"flex",}}>
      <DirectionsCarIcon style={{color: "#00B0E6",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.doors && res.doors} Doors</p>
  </div>
    <div style={{display:"flex",}}>
      <MeetingRoomIcon style={{color: "#00B0E6",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.petrol && res.petrol}</p>
  </div>
    <div style={{display:"flex",}}>
      <SupervisorAccountIcon style={{color: "#00B0E6",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.person && res.person} Person</p>
  </div>
    
  </div>
  <div>
    <div style={{display:"flex",}}>
      <WhatshotIcon style={{color: "#00B0E6",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.cc && res.cc} cc</p>
  </div>
    <div style={{display:"flex",}}>
      <ReceiptIcon style={{color: "#00B0E6",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.ac && res.ac === "yes" ? "Ac" : "No AC"}</p>
  </div>
    <div style={{display:"flex",}}>
      <LocalMallIcon style={{color: "#00B0E6",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.luggage && res.luggage} luggage</p>
  </div>
    
  </div>
  </div>
  
  :""
                        
                      }
                            <div className="product-action position-relative visible">
                          <a className="btn-product btn-cart text-uppercase text-dark text-decoration-none" title="Book Now" onClick={()=>addtocartproduct([res])}>
                              <span className="text-dark shadow-none">Book Now</span>
                            </a>
                          </div>
                              
                          </div>{/* End .product-body */}
                        </div>{/* End .product */}
                      </div>
 )
}
if(res.Product_Sub_Catagories !== ""){
  console.log("323")
  return(
<div className="col-6 col-md-4 col-lg-4 col-xl-3">
                        <div className="product product-7 text-center" style={{height : "auto"}}>
                          <figure className="product-media">
                            <span className="product-label label-new">New</span>
                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                              <img src={res.Product_Image_Upload} alt="Product image" className="product-image" />
                            </Link>
                            <div className="product-action-vertical">
                              <a className="btn-product-icon btn-wishlist btn-expandable"  onClick={()=>addtocartproduct8([res])}><span>add to wishlist</span></a>
                              <Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></Link>
                              {/* <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a> */}
                            </div>{/* End .product-action-vertical */}
                           {/* End .product-action */}
                          </figure>{/* End .product-media */}
                          <div className="product-body">
                            <div className="product-cat">
                              <Link to={"/categories/"+res.Product_Catagories}>{res.Product_Catagories}</Link>
                            </div>{/* End .product-cat */}
                            <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden"}}><Link to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link></h3>{/* End .product-title */}
                            <div className="product-price">
                            
                              {res.Product_Price_Discounted ? 
                  <><span className="product-price">Rs. {res.Product_Price_Discounted}</span><span className="text-muted ml-2" style={{padding :"0px"}}><del className="product-price" style={{color : "grey"}}>Rs. {(res.Product_Price)}</del></span></>
                  :
                  <span>
                  Rs. {(res.Product_Price)}
                  </span> 
                  
                                    }   


                            </div>{/* End .product-price */}
                                
{ res && res.luggage &&  res.luggage > 0 ?
  <div style={{display :"flex",flexDirection : "row",justifyContent :"space-around",padding:"5px 0px",width:"100%",margin:"auto"}}>
  <div>
    <div style={{display:"flex",}}>
      <DirectionsCarIcon style={{color: "#00B0E6",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.doors && res.doors} Doors</p>
  </div>
    <div style={{display:"flex",}}>
      <MeetingRoomIcon style={{color: "#00B0E6",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.petrol && res.petrol}</p>
  </div>
    <div style={{display:"flex",}}>
      <SupervisorAccountIcon style={{color: "#00B0E6",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.person && res.person} Person</p>
  </div>
    
  </div>
  <div>
    <div style={{display:"flex",}}>
      <WhatshotIcon style={{color: "#00B0E6",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.cc && res.cc} cc</p>
  </div>
    <div style={{display:"flex",}}>
      <ReceiptIcon style={{color: "#00B0E6",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.ac && res.ac === "yes" ? "Ac" : "No AC"}</p>
  </div>
    <div style={{display:"flex",}}>
      <LocalMallIcon style={{color: "#00B0E6",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.luggage && res.luggage} luggage</p>
  </div>
    
  </div>
  </div>
  
  :""
                        
                      }
                            <div className="product-action position-relative visible">
                          <a className="btn-product btn-cart text-uppercase text-dark text-decoration-none" title="Book Now" onClick={()=>addtocartproduct([res])}>
                              <span className="text-dark shadow-none">Book Now</span>
                            </a>
                          </div>
                              
                          </div>{/* End .product-body */}
                        </div>{/* End .product */}
                      </div>
   )
  }
  else{
    return(
<div className="col-6 col-md-4 col-lg-4 col-xl-3">
                        <div className="product product-7 text-center" style={{height : "auto"}}>
                          <figure className="product-media">
                            <span className="product-label label-new">New</span>
                            <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                              <img src={res.Product_Image_Upload} alt="Product image" className="product-image" />
                            </Link>
                            <div className="product-action-vertical">
                              <a className="btn-product-icon btn-wishlist btn-expandable"  onClick={()=>addtocartproduct8([res])}><span>add to wishlist</span></a>
                              <Link to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id} className="btn-product-icon btn-quickview" title="Quick view"><span>Quick view</span></Link>
                              {/* <a href="#" className="btn-product-icon btn-compare" title="Compare"><span>Compare</span></a> */}
                            </div>{/* End .product-action-vertical */}
                           {/* End .product-action */}
                          </figure>{/* End .product-media */}
                          <div className="product-body">
                            <div className="product-cat">
                              <Link to={"/categories/"+res.Product_Catagories}>{res.Product_Catagories}</Link>
                            </div>{/* End .product-cat */}
                            <h3 className="product-title"   style={{maxWidth:"100%",maxHeight : "59px",overflow : "hidden"}}><Link to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link></h3>{/* End .product-title */}
                            <div className="product-price">
                            
                              {res.Product_Price_Discounted ? 
                  <><span className="product-price">Rs. {res.Product_Price_Discounted}</span><span className="text-muted ml-2" style={{padding :"0px"}}><del className="product-price" style={{color : "grey"}}>Rs. {(res.Product_Price)}</del></span></>
                  :
                  <span>
                  Rs. {(res.Product_Price)}
                  </span> 
                  
                                    }   


                         
                            </div>{/* End .product-price */}
                            
{ res && res.luggage &&  res.luggage > 0 ?
  <div style={{display :"flex",flexDirection : "row",justifyContent :"space-around",padding:"5px 0px",width:"100%",margin:"auto"}}>
  <div>
    <div style={{display:"flex",}}>
      <DirectionsCarIcon style={{color: "#00B0E6",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.doors && res.doors} Doors</p>
  </div>
    <div style={{display:"flex",}}>
      <MeetingRoomIcon style={{color: "#00B0E6",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.petrol && res.petrol}</p>
  </div>
    <div style={{display:"flex",}}>
      <SupervisorAccountIcon style={{color: "#00B0E6",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.person && res.person} Person</p>
  </div>
    
  </div>
  <div>
    <div style={{display:"flex",}}>
      <WhatshotIcon style={{color: "#00B0E6",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.cc && res.cc} cc</p>
  </div>
    <div style={{display:"flex",}}>
      <ReceiptIcon style={{color: "#00B0E6",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.ac && res.ac === "yes" ? "Ac" : "No AC"}</p>
  </div>
    <div style={{display:"flex",}}>
      <LocalMallIcon style={{color: "#00B0E6",fontSize : "13",marginRight  :"3"}}/>
      <p style={{fontSize:9,fontWeight :"600",color:"black"}}>{res && res.luggage && res.luggage} luggage</p>
  </div>
    
  </div>
  </div>
  
  :""
                        
                      }
                            <div className="product-action position-relative visible">
                          <a className="btn-product btn-cart text-uppercase text-dark text-decoration-none" title="Book Now" onClick={()=>addtocartproduct([res])}>
                              <span className="text-dark shadow-none">Book Now</span>
                            </a>
                          </div>
                              
                          </div>{/* End .product-body */}
                        </div>{/* End .product */}
                      </div>

                    )
                  }
                  }
                  })
                    
                  }


                      
                    </div>{/* End .row */}
                  </div>{/* End .products */}
                  <nav aria-label="Page navigation">
                    <ul className="pagination justify-content-center">
                      <li className="page-item disabled">
                        <a className="page-link page-link-prev" href="#" aria-label="Previous" tabIndex={-1} aria-disabled="true">
                          <span aria-hidden="true"><i className="icon-long-arrow-left" /></span>Prev
                        </a>
                      </li>
                      <li className="page-item active" aria-current="page"><a className="page-link" href="#">1</a></li>
                      {/* <li className="page-item"><a className="page-link" href="#">2</a></li>
                      <li className="page-item"><a className="page-link" href="#">3</a></li>
                      <li className="page-item-total">of 6</li> */}
                      <li className="page-item">
                        <a className="page-link page-link-next" href="#" aria-label="Next">
                          Next <span aria-hidden="true"><i className="icon-long-arrow-right" /></span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>{/* End .col-lg-9 */}
                
              </div>{/* End .row */}
            </div>{/* End .container */}
          </div>{/* End .page-content */}
        </main>{/* End .main */}




</>
        )
    }



export default SubCategoriesProduct;
import React, { useState , useEffect } from 'react';

import swal from 'sweetalert';

import { Link } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import logo1 from '../assets/images/logo1.png';

const Card = (props)=> {
    // const [price , setPrice] = useState(1)
    const [cart , setCart] = useState([])
    const [total , settotal] = useState(0)
    const [total1 , settotal1] = useState(0)
    const [price , setPrice] = useState([1,1,1,1,1,1,1,1,1,1,1,1,1])
    const [peice , setpeice] = useState([1,1,1,1,1,1,1,1,1,1,1,1,1])
    const [discount , setdiscount] = useState([])
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [promo , setpromo] = useState("")
    const [dis , setdis] = useState(true)
    const [totalpro , settotalpro] = useState(0)
    const [discount6 , setdiscount6] = useState({})
    const [total2 , settotal2] = useState(0)
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    const [admindataall , setadmindataall ] = useState(0)

    
    useEffect(() =>{
        window.scrollTo(0, 0)

        setpromo(JSON.parse(localStorage.getItem("discount")) ? JSON.parse(localStorage.getItem("discount")).name : "")
        
        
        
        
        fetch("https://anas-rent-a-car-main-back.vercel.app/admindata",{
    method: "GET",
     headers :  {
     "Content-Type" : "application/json" , 
 } ,
})
.then(res18=>res18.json())
.then(res19=>{
  console.log(res19)
  setadmindataall(res19[0].DeliveryCharges >  0 ? res19[0].DeliveryCharges : 0)

        })

       
        
        
        
        fetch("https://anas-rent-a-car-main-back.vercel.app/AllCreateDisCountPrice",{
          method: "GET",
          headers :  {
          "Content-Type" : "application/json" , 
      } ,
      })
      .then(res7=>res7.json())
      .then(res8=>{
       setdiscount(res8)
       let data5 =  JSON.parse(localStorage.getItem("CartPrice")) 
       settotal1(data5)
       settotal2(data5)
      })
      if(JSON.parse(localStorage.getItem("discount")) ){
        setdiscount6(JSON.parse(localStorage.getItem("discount")))
      }
      else{
        setdiscount6({fg : "3"})
      }
        
      const Inter = setInterval(()=>{
        let data =  JSON.parse(localStorage.getItem("Cart")) 
        if(data)
       setCart(data)
       else      setCart(0)

       let data5 =  JSON.parse(localStorage.getItem("CartPrice")) 
       settotal1(data5)
       settotal2(data5)
      
       if(JSON.parse(localStorage.getItem("discount")) ){
         setdiscount6(JSON.parse(localStorage.getItem("discount")))
        var d = document.getElementById("discount")
        var d1 = document.getElementById("discount1")
        if(JSON.parse(localStorage.getItem("discount")).discount > 1){
            d.innerHTML = "Rs " +JSON.parse(localStorage.getItem("discount")).discount
     
        }
        else{
            d.innerHTML = " " +JSON.parse(localStorage.getItem("discount")).discount1 +" % Off"
       
        }
        console.log(discount6)
    }
    else{
      var d = document.getElementById("discount")

      d.innerHTML = "No Discount" 

    }
    },1000)

    let data =  JSON.parse(localStorage.getItem("Cart")) 
    if(data)
   { 
       setCart(data)
       data.map((res,i)=>{
        peice[i] = res.Pieces
           price[i] = res.Pieces
       })
       console.log(price,peice)
    }
    else      setCart(0)

if (data) {
        var j = 0
        data.map((item,i)=>{
           j = item.Total_Product_Price + j
        })
        settotal1(j)
        localStorage.setItem("CartPrice" ,JSON.stringify(j))
    }
    else{
        settotal1(0)

    }
console.log(total1,j)

    return () => {
 
      clearInterval(Inter);
      updateCard1()
    }


        
        
        
        
        
        
        
  },[])
  
  const removeDatas = (item) =>{
 
    let data1;
    if(!item.Size){
         data1 = cart.filter((res,i)=>{
            return res._id !== item.id
        })
    }
    else{
         data1 = cart.filter((res,i)=>{
            return res.Size !== item.Size
        })
    }
    localStorage.setItem("Cart" ,JSON.stringify(data1))
        var j = 0
    data1.map((res,i)=>{
         j += res.Total_Product_Price
    })
    if ( j !== 0){
        localStorage.setItem("CartPrice",JSON.stringify( j))
        settotal1(j)
    }
    else{
        localStorage.setItem("CartPrice",JSON.stringify( 0))
        settotal1(0)
    }
    
    
  }


  const minus=(e)=>{
    if(peice[e] > 1)
    peice[e] = (peice[e] - 1)
    // console.log(peice[e],peice);
    if(dis)
    setdis(true)
    else setdis(false)}
const plus=(e)=>{
    if(peice[e] < 100)
    peice[e] = (peice[e] + 1)
    // console.log(peice[e],peice);
    if(dis)
    setdis(true)
    else setdis(false)


}









const updateCard1 = (id) =>{
    localStorage.removeItem("CartPrice")
      let data3 =  JSON.parse(localStorage.getItem("Cart")) 
      let arr = []
      arr = peice
    
      const pro = data3.map((res,i)=>{
        if(res.Size_Discounted.length === 0){
            res.Stock_Quantity.map((res1,j)=>{
                if(res.Size === res1){
                    return {
                        ...res , Pieces : arr[i] , Total_Product_Price : res.Price[j] * arr[i] 
                    }
                }
            })
        }
//         if(res.Size_Discounted.length > 0){
//         res.Stock_Quantity.map((res1,j)=>{
//             if(res.Size === res1){
//           return {
//               ...res , Pieces : arr[i] , Total_Product_Price : res.Size_Discounted * arr[i] 
//           }
//         }
//     })
// }
        if(res.Product_Price_Discounted)
          return {
              ...res , Pieces : arr[i] , Total_Product_Price : res.Product_Price_Discounted * arr[i] 
          }
          else 
          return {
              ...res , Pieces : arr[i] , Total_Product_Price : res.Product_Price * arr[i] 
          }
      })
      console.log(pro)
      setCart(pro)
     localStorage.removeItem("Cart")
      localStorage.setItem("Cart",JSON.stringify(pro))
      var j = 0
      pro.map((item,i)=>{
        console.log(item.Total_Product_Price)
         j = item.Total_Product_Price + j
      })
      settotal1(j)
      localStorage.setItem("CartPrice" ,JSON.stringify(j))
      // swal("Update!", "Your Cart is Updated!", "success");
  
      // props.history.push("/card")
          
      // }
      
    }
  
    const setCouopon = () =>{
     
        var d = document.getElementById("discount")
        var d1 = document.getElementById("discount1")
        const the = discount.filter((res,i)=> res.name === promo)
        console.log(the)
        if(the.length === 0){
          swal("Please enter a correct promo code");
          document.getElementById("grand-total").innerHTML = total1
          localStorage.removeItem("discount")
          return
        }
        if(the[0].discount === null &&the[0].discount1 === null){
          swal("Please enter a correct promo code");
          document.getElementById("grand-total").innerHTML = total1
          localStorage.removeItem("discount")
          return
          
        }
        if(the.length > 0){
      
          setdiscount6(the[0])
          if( the[0].discount > 1){
            settotalpro((parseInt(total1) -parseInt(total1) * parseInt(the[0].discount))/100)
            // settotal1((total1 * the[0].discount1)/100)
            localStorage.setItem("discount",JSON.stringify(the[0]))
            // d.innerHTML = "Rs " +the[0].discount
            // d.innerHTML = "Discount : "
            swal("Update!","Successfully your Promo Applied ", "success");
            
          }
          else{
            
            console.log(total1 -(total1 * the[0].discount1)/100)
            settotalpro((parseInt(total1) -(parseInt(total1) * parseInt(the[0].discount1))/100))
            // settotal1((total1 * the[0].discount1)/100)
            // console.log(total1 , (total1/the[0].discount1))
            localStorage.setItem("discount",JSON.stringify(the[0]))
            // d.innerHTML = " " +the[0].discount1 +" % Off"
            // d.innerHTML = "Discount : "
            swal("Update!","Successfully your Promo Applied ", "success");
      
          }
        }
        else{
          swal("Please enter a correct promo code");
        }
          
        }
   
        return (
          <div className="page-wrapper">
          
          <main className="main">
            <div className="page-header text-center" style={{backgroundImage: 'url("assets/images/page-header-bg.jpg")'}}>
              <div className="container">
                <h1 className="page-title">Cart<span></span></h1>
              </div>{/* End .container */}
            </div>{/* End .page-header */}
            <nav aria-label="breadcrumb" className="breadcrumb-nav">
              <div className="container">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="">Home</a></li>
                  {/* <li className="breadcrumb-item"><a href="#">Shop</a></li> */}
                  <li className="breadcrumb-item active" aria-current="page">Cart</li>
                </ol>
              </div>{/* End .container */}
            </nav>{/* End .breadcrumb-nav */}
            <div className="page-content">
              <div className="cart">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-9">
                      <table className="table table-cart table-mobile">
                        <thead>
                          <tr>
                            <th>Cars</th>
                            <th>Price</th>
                            <th>Days</th>
                            <th>Total</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                        {
                          cart.length >= 1 ? cart.map((item,i)=>{
                            return(

                              <tr>
                                <td className="product-col">
                                  <div className="product">
                                    <figure className="product-media">
                                      <a href="">
                                        <img src={item.Product_Image_Upload} alt="Product image"  onClick={()=>props.history.push("/categories/"+item.Product_Catagories+"/"+item.Product_Sub_Catagories+"/"+item.Course_Sub_Catagories+"/all-products/"+item.Product_Name+"/"+item._id)} />
                                      </a>
                                    </figure>
                                    <h3 className="product-title">
                                      <div style={{overflow : "hidden" , height:"40px"}}>
                                      <a href="#">{item.Product_Name}</a>
                                      {item.Price[0] ? 
                                        <b><br/>Varient : <a href="">{item.Size}</a></b>
                                      : ""

                                      }
                                      </div>
                                    </h3>{/* End .product-title */}
                                  </div>{/* End .product */}
                                </td>
                                <td className="price-col">Rs. {(item.Total_Product_Price /  item.Pieces ).toFixed(0)}</td>
                                <td className="quantity-col">
                                <div class="product-details-quantity quantity-centered">
                                  <div class="input-group input-spinner">
                                    <div class="input-group-prepend">
                                      <button style={{minWidth: "26px"}} class="btn btn-decrement btn-spinner" type="button" onClick={()=>minus(i)} ><i class="icon-minus"></i>
                                      </button>
                                    </div>
                                    <input type="text" style={{textAlign: "center"}} class="form-control " required placeholder=""   value={peice[i]} size={4} defaultValue={1} min={0} step={1}/>
                                    <div class="input-group-append">
                                      <button style={{minWidth: "26px"}} class="btn btn-increment btn-spinner" type="button"  onClick={()=>plus(i)}><i class="icon-plus"></i>
                                      </button>
                                    </div>
                                    </div>
                                </div>
                                  {/* <div className="cart-product-quantity">
                                    <input type="number" className="form-control"  min={1} step={1} data-decimals={0} required  value={peice[i]} size={4} defaultValue={1} min={0} step={1}/>
                                  </div> */}
                                </td>
                                <td className="total-col">Rs. {((item.Total_Product_Price /  item.Pieces)* peice[i]).toFixed(0)}</td>
                                <td className="remove-col"><button className="btn-remove"  onClick={()=>removeDatas(item)} ><i className="icon-close" /></button></td>
                              </tr>



                          )
                        })
                        : <tr><td colSpan="6"><div className="col-sm-12 col-lg-12 mb-12"><center> <h3> No Product in Cart</h3></center> </div></td></tr> 
                      }

                          





                          
                        </tbody>
                      </table>{/* End .table table-wishlist */}
                      <div className="cart-bottom">
                        <div className="cart-discount">
                          <div >
                            <div className="input-group">
                              <input type="text" className="form-control" required placeholder="coupon code"  value={promo} onChange={(e)=>setpromo(e.target.value)}/>
                              <div className="input-group-append">
                                <button className="btn btn-outline-primary-2"  onClick={()=>setCouopon()}><i className="icon-long-arrow-right" /></button>
                              </div>{/* .End .input-group-append */}
                            </div>{/* End .input-group */}
                          </div>
                        </div>{/* End .cart-discount */}
                        <a className="btn btn-outline-dark-2" onClick={()=>window.location.reload(false)
}><span>UPDATE CART</span><i className="icon-refresh" /></a>
                      </div>{/* End .cart-bottom */}
                    </div>{/* End .col-lg-9 */}
                    <aside className="col-lg-3">
                      <div className="summary summary-cart">
                        <h3 className="summary-title">Cart Total</h3>{/* End .summary-title */}
                        <table className="table table-summary">
                          <tbody>
                            <tr className="summary-subtotal">
                              <td>Subtotal:</td>
                              <td>Rs. {total1

}</td>
                            </tr>{/* End .summary-subtotal */}
                            {

                            }
                            <tr className="summary-subtotal">
                              <td>Discount</td>
                              <td id="discount"></td>
                            </tr>
                            <tr className="summary-subtotal">
                              <td></td>
                              <td>

                              { admindataall > 0 ?
                                                            "Rs. "+admindataall
                                                            : 
                                                            <b>Without Tax</b>
                                                         }
                              </td>
                            </tr>
                            
                            {/* End .summary-subtotal */}
                            {/* <tr className="summary-shipping">
                              <td>Shipping:</td>
                              <td>&nbsp;</td>
                            </tr> */}
                            {/* <tr className="summary-shipping-row">
                              <td>
                                <div className="custom-control custom-radio">
                                  <input type="radio" id="free-shipping" name="shipping" className="custom-control-input" />
                                  <label className="custom-control-label" htmlFor="free-shipping">Free Shipping</label>
                                </div>
                              </td>
                              <td>Rs. 0.00</td>
                            </tr>
                            <tr className="summary-shipping-row">
                              <td>
                                <div className="custom-control custom-radio">
                                  <input type="radio" id="standart-shipping" name="shipping" className="custom-control-input" />
                                  <label className="custom-control-label" htmlFor="standart-shipping">Standart:</label>
                                </div>
                              </td>
                              <td>Rs. 10.00</td>
                            </tr>
                            <tr className="summary-shipping-row">
                              <td>
                                <div className="custom-control custom-radio">
                                  <input type="radio" id="express-shipping" name="shipping" className="custom-control-input" />
                                  <label className="custom-control-label" htmlFor="express-shipping">Express:</label>
                                </div>
                              </td>
                              <td>Rs. 20.00</td>
                            </tr> */}
                            {/* End .summary-shipping-row */}







                            {/* <tr className="summary-shipping-estimate">
                              <td>Estimate for Your Country<br /> <a href="dashboard.html">Change address</a></td>
                              <td>&nbsp;</td>
                            </tr> */}











                            {/* End .summary-shipping-estimate */}
                            <tr className="summary-total">
                              <td>Total :</td>
                              <td>Rs.  
                              {
   discount6.discount ?
   " "+ (parseInt(total1) - parseInt(discount6.discount) +parseInt(admindataall) )
    : ""
  }

   { discount6.discount1 ? 
   " "+parseInt((parseInt(total1) -( parseInt(total1) * parseInt(discount6.discount1))/100)+parseInt(admindataall)) :
   ""
  }
    {discount6.fg === "3" ? 
    total2+admindataall : ""
    
  }
    {/* {JSON.parse(localStorage.getItem("discount")) && JSON.parse(localStorage.getItem("discount")).discount2 ? 
    " "+parseInt((parseInt(total2) -( parseInt(total2) * parseInt(JSON.parse(localStorage.getItem("discount")).discount2))/100)+parseInt(admindataall)) :
    ""
    } */}
            </td>
                            </tr>{/* End .summary-total */}
                          </tbody>
                        </table>{/* End .table table-summary */}
                        <Link to="/checkout" className="btn btn-outline-primary-2 btn-order btn-block">PROCEED TO CHECKOUT</Link>
                      </div>{/* End .summary */}
                      {/* <Link to="/"  className="btn btn-outline-dark-2 btn-block mb-3"><span>CONTINUE SHOPPING</span><i className="icon-refresh" /></Link> */}
                    </aside>{/* End .col-lg-3 */}
                  </div>{/* End .row */}
                </div>{/* End .container */}
              </div>{/* End .cart */}
            </div>{/* End .page-content */}
          </main>{/* End .main */}
        </div>            
 
        )
    }


export default Card;